// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = window.location.origin + '/tokens_list.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL]

export const QDAY_DEFAULT_LIST = {
  name: 'QDAY',
  logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/compound-interface.svg',
  keywords: [],
  timestamp: '2021-05-27T20:37:00.000+00:00',
  tokens: [
    {
      chainId: 44003,
      address: '0x2C7Bc3A1176bC95A720bcBe6961500ca36e7F2BB',
      name: 'Swap PQ USD',
      symbol: 'swPQUSD',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDT.svg'
    },
    {
      chainId: 44003,
      address: '0xaCd675bc8acA6831E563adDc0aB3665c7059e05d',
      name: 'Bridge PQ USD',
      symbol: 'brPQUSD',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDC.svg'
    }
  ],
  version: {
    major: 2,
    minor: 0,
    patch: 0
  }
}
